
	/*	import {
		ElLoading
	} from 'element-plus'*/

	export default {
		name: 'System',
		components: {},
		data() {
			return {
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
				activeName: 'jibenshezhi',
				tabPosition: 'top',
				labelPosition: 'right',
				tabstyle: {},
				form: {
					title: '',
					type: [],
					desc: '',
				},
				sysmods: [
					{
						"key": "basic",
						"text": "基础功能"
					},
					{
						"key": "store",
						"text": "基础功能"
					},
					{
						"key": "promotion",
						"text": "基础功能"
					},
				],
				modsel: ['basic']
			}
		},
		created() {
			window.addEventListener('resize', this.handleResize)
		},
		methods: {
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
				this.clientHeight = document.documentElement.clientHeight;
			},
			handleClick(tab, event) {
				console.log(tab);
				console.log(event);
			},
			onSubmit() {
				
			}
		},
		mounted() {
			if (this.clientWidth <= 768) {
				this.tabPosition = 'top';
				this.labelPosition = 'top'
			} else {
				this.tabPosition = 'top';
				this.labelPosition = 'right'
			}
			//this.tabstyle = {height: (document.documentElement.clientHeight - 60) + 'px'};
		},
		watch: {
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						that.timerw = false;
						console.log('System-' + that.clientWidth);
						if (that.clientWidth <= 768) {
							that.tabPosition = 'top';
							that.labelPosition = 'top'
						} else {
							that.tabPosition = 'top';
							that.labelPosition = 'right'
						}
					}, 400)
				}
			},
			clientHeight(val) {
				if (!this.timerh) {
					// 一旦监听到的clientHeight值改变，就将其重新赋给data里的clientHeight
					this.clientHeight = val
					this.timerh = true
					let that = this
					setTimeout(function() {
						that.tabstyle = {
							height: (document.documentElement.clientHeight - 60) + 'px'
						};
						that.timerh = false;
					}, 400)
				}
			}
		}
	}
